import React, { createContext, useContext, useState } from 'react';

interface IBrandProviderProps {
    children?: React.ReactNode;
    brand: IBrand;
}
interface IBrandContext {
    brand: IBrand;
}

const BrandContext = createContext<IBrandContext>({
    brand: {
        analyticsGoogle: null,
        currencyCode: '',
        currencyMultiplier: 100,
        customDomain: null,
        galleryDomain: '',
        id: 0,
        isEligibleToManagePlan: true,
        isEmailVerified: false,
        links: {},
        locale: '',
        name: '',
        subdomain: '',
        type: 'brand'
    }
});

const BrandProvider: React.FC<IBrandProviderProps> = ({ brand, ...props }) => {
    const [storedBrand] = useState(brand);

    return <BrandContext.Provider value={{ brand: storedBrand }} {...props} />;
};

export const withBrand = <T extends object>(
    Component: React.ComponentType<T>
): React.FC<T & { brand: IBrand }> => {
    const WithBrandComponent: React.FC<T & { brand: IBrand }> = (props) => {
        return (
            <BrandProvider brand={props.brand}>
                <Component {...props} />
            </BrandProvider>
        );
    };

    return WithBrandComponent;
};

export const useBrand = () => {
    const { brand } = useContext(BrandContext);

    return brand;
};
