import React from 'react';
import { Box, styled } from 'sp-ui';
import { useBrand } from '../hooks/BrandContext';
import { useBrandHomepage } from '../hooks/BrandHomepageContext';
import BrandLogo from './BrandLogo';
import Nav from './Nav';

const HeaderContainer = styled(Box)`
    padding: 0 20px 40px 20px;
    top: 0;
    position: static;
    text-align: center;
    margin: auto;
    p {
        font-family: 'OpenSans Regular';
        color: #a5a5a5;
    }
`;

const Header: React.FC = () => {
    const brandData = useBrandHomepage();
    const { name: brandName } = useBrand();
    const { enableAboutPage, enableContactForm: contactFormEnabled } = brandData;
    const showNavOnPage = enableAboutPage || contactFormEnabled;

    return (
        <HeaderContainer data-testid="brand-header">
            <BrandLogo brandName={brandName} />
            {showNavOnPage && <Nav brandData={brandData} />}
        </HeaderContainer>
    );
};

export default Header;
