import React from 'react';
import { useParams } from 'react-router-dom';

const ConfirmationPage = () => {
    const { sessionTypeSlug } = useParams();

    return <div>This is the confirmation page for: {sessionTypeSlug}</div>;
};

export default ConfirmationPage;
