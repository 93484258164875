import { DurationUnit, NoticeUnit } from 'studio_react/booking/types';
import { useTranslate } from 'ts/common';

export const useTranslatedTimeUnits = (
    unit: DurationUnit | NoticeUnit | null,
    quantity: number | null | undefined = 1
) => {
    const translate = useTranslate();

    switch (unit) {
        default:
        case 'minute':
            return quantity === 1
                ? translate('durationUnitMinute')
                : translate('durationUnitMinutes');
        case 'hour':
            return quantity === 1 ? translate('durationUnitHour') : translate('durationUnitHours');
        case 'day':
            return quantity === 1 ? translate('durationUnitDay') : translate('durationUnitDays');
    }
};
