import React, { useCallback, useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ButtonProps } from 'ts/common/components/gallery';
import { useBreakpoint, useTranslate } from 'ts/common/hooks';
import { useClientBookingData } from 'client_react/booking/App';
import MobileDateAndTimePage from './MobileDateAndTimePage';
import { ROUTE } from './common';
import ResponsiveButtonGroup from './components/ResponsiveButtonGroup';

const DateAndTimePage = () => {
    const { bookingSessionType, updateBookingSession, isPending, navigateWithSession } =
        useClientBookingData();
    const t = useTranslate('booking.dateAndTimePage');
    const isLargeScreen = useBreakpoint();

    const handleContinueClick = useCallback(async () => {
        if (!bookingSessionType) {
            return;
        }

        const startDate = new Date();

        const bookingSessionPublicId = await updateBookingSession({
            sessionTypePublicId: bookingSessionType.publicId,
            startDate: startDate.toISOString()
        });

        if (bookingSessionPublicId) {
            navigateWithSession(ROUTE.CONTACT_INFORMATION, bookingSessionPublicId);
        }
    }, [bookingSessionType, navigateWithSession, updateBookingSession]);

    const buttons = useMemo<ButtonProps[]>(
        () => [
            {
                text: t('continue'),
                onClick: handleContinueClick,
                isLoading: isPending
            }
        ],
        [handleContinueClick, isPending, t]
    );

    if (!bookingSessionType) {
        return null;
    }

    if (isLargeScreen) {
        return (
            <Flex direction="column" gap="24px">
                <Text>{bookingSessionType.description}</Text>

                <Text fontWeight={600} textAlign="center">
                    [Date and Time Picker Here]
                </Text>

                <ResponsiveButtonGroup buttons={buttons} />
            </Flex>
        );
    }

    return (
        <MobileDateAndTimePage
            bookingSessionType={bookingSessionType}
            onSubmit={handleContinueClick}
        />
    );
};

export default DateAndTimePage;
