import { useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Route from 'Sp/Route';
import { FetchMethod, IFetchHookOptions } from 'ts/common/hooks';
import { IBookingSession } from 'client_react/booking/types';
import { useClientApiFetch } from 'client_react/bootstrap';
import { API, QUERY } from './common';

export default function useBookingSession(bookingSessionId: string | null) {
    const navigate = useNavigate();

    const {
        performFetch: getBookingSession,
        loading: isGetting,
        response: getResponse
    } = useClientApiFetch<IBookingSession>(`${API.BOOKING_SESSION}/${bookingSessionId}`, {
        defer: true,
        method: FetchMethod.GET
    });

    const {
        performFetch: postBookingSession,
        loading: isPosting,
        response: postResponse
    } = useClientApiFetch<IBookingSession>(API.BOOKING_SESSION, {
        defer: true,
        method: FetchMethod.POST
    });

    const {
        performFetch: patchBookingSession,
        loading: isPatching,
        response: patchResponse
    } = useClientApiFetch<IBookingSession>(`${API.BOOKING_SESSION}/${bookingSessionId}`, {
        defer: true,
        method: FetchMethod.PATCH
    });

    const {
        performFetch: postBookingSessionPayment,
        loading: isPostingPayment,
        response: postPaymentResponse
    } = useClientApiFetch<IBookingSession>(`${API.BOOKING_SESSION}/${bookingSessionId}/payment`, {
        defer: true,
        method: FetchMethod.POST
    });

    const bookingSession = postPaymentResponse || patchResponse || postResponse || getResponse;

    useEffect(() => {
        // Fetch the booking session if we haven't already
        if (bookingSessionId && !postResponse && !getResponse) {
            getBookingSession();
        }
    }, [bookingSessionId, getBookingSession, getResponse, postResponse]);

    const navigateWithSession = useCallback(
        (to: string, bookingSessionPublicId?: string) => {
            const route = Route.get(to, {
                [QUERY.BOOKING_SESSION_ID]: bookingSessionPublicId ?? bookingSession?.publicId
            });

            navigate(route);
        },
        [bookingSession?.publicId, navigate]
    );

    /**
     * @return publicId if the booking session was successfully updated
     */
    const updateBookingSession = useCallback(
        async (data: IFetchHookOptions['data']) => {
            if (bookingSession) {
                await patchBookingSession({ data });
            } else {
                const response = await postBookingSession({ data });

                if (response?.status === 201) {
                    return response.publicId;
                }
            }

            return bookingSessionId;
        },
        [bookingSession, bookingSessionId, patchBookingSession, postBookingSession]
    );

    const submitPayment = useCallback(
        async (data: IFetchHookOptions['data']) => {
            const response = await postBookingSessionPayment({ data });

            if (response) {
                // TODO navigate()?
            }
        },
        [postBookingSessionPayment]
    );

    // We may want to separate getting/posting pending states in the future
    return {
        isPending: isGetting || isPosting || isPostingPayment || isPatching,
        bookingSession,
        updateBookingSession,
        navigateWithSession,
        submitPayment
    };
}
