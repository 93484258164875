import React, { useMemo } from 'react';
import { useTheme } from '@chakra-ui/react';
import { Theme } from '@emotion/react';
import { Elements } from '@stripe/react-stripe-js';
import { Appearance, StripeElementsOptions } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import { PaymentForm } from 'client_react/booking/components/PaymentForm';

const getAppearanceFromTheme = (theme: Theme): Appearance => {
    return {
        rules: {
            '.Checkbox': {
                color: theme.isLightColorScheme ? theme.colors.gray[500] : theme.colors.white,
                fontSize: '14px'
            },
            '.Input:focus': {
                borderColor: theme.colors.brand.primary,
                borderWidth: '2px',
                boxShadow: 'none'
            },
            '.Label': {
                color: theme.isLightColorScheme ? theme.colors.gray[500] : theme.colors.white,
                fontSize: '14px'
            }
        },
        theme: theme.isLightColorScheme ? 'stripe' : 'night',
        variables: {
            colorPrimary: theme.colors.brand.primary,
            fontFamily: theme.fonts.body as string
        }
    };
};

interface IPaymentFormContainerProps {
    amount: number;
    currencyCode: string;
    stripeConfigId: string;
    stripePublicKey: string;
    stripeStandardAccountId: Nullable<string>;
}

export const PaymentFormContainer: React.FC<IPaymentFormContainerProps> = ({
    amount,
    currencyCode,
    stripePublicKey,
    stripeStandardAccountId
}) => {
    const stripeInstance = useMemo(() => {
        return loadStripe(stripePublicKey, {
            apiVersion: '2023-10-16',
            stripeAccount: stripeStandardAccountId ?? undefined
        });
    }, [stripePublicKey, stripeStandardAccountId]);

    const theme = useTheme() as unknown as Theme;

    const stripeOptions: StripeElementsOptions = useMemo(() => {
        return {
            appearance: getAppearanceFromTheme(theme),
            currency: currencyCode.toLowerCase(),
            paymentMethodCreation: 'manual',

            amount: amount,
            mode: 'payment'
        };
    }, [amount, currencyCode, theme]);

    return (
        <Elements options={stripeOptions} stripe={stripeInstance}>
            <PaymentForm />
        </Elements>
    );
};
