import React, { useMemo } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import moment from 'moment';
import { Link, Phone } from 'react-feather';
import { CalendarIcon, ClockIcon, CurrencyIcon, LocationIcon } from 'ts/common/components/icons';
import { useTranslatedTimeUnits } from 'ts/common/hooks/useTranslatedTimeUnits';
import { IBookingSession, IBookingSessionType } from 'client_react/booking/types';

export function SessionSummary({
    bookingSessionType,
    bookingSession
}: {
    bookingSessionType: IBookingSessionType;
    bookingSession: IBookingSession | null;
}) {
    const durationString = useTranslatedTimeUnits(
        bookingSessionType.durationUnit,
        bookingSessionType.duration
    );

    const { locationIcon, locationValue } = useMemo(() => {
        switch (bookingSessionType.locationType) {
            case 'phone':
                return {
                    locationIcon: <Phone viewBox="-2 -2 28 28" />,
                    locationValue: bookingSessionType.phoneNumber
                };
            case 'url':
                return {
                    locationIcon: <Link viewBox="-2 -2 28 28" />,
                    locationValue: bookingSessionType.url
                };
            default:
                return {
                    locationIcon: <LocationIcon />,
                    locationValue: bookingSessionType.address
                };
        }
    }, [
        bookingSessionType.address,
        bookingSessionType.locationType,
        bookingSessionType.phoneNumber,
        bookingSessionType.url
    ]);

    return (
        <Flex
            flexDirection={{ base: 'column', lg: 'row' }}
            alignItems={{ base: 'left', lg: 'center' }}
            gap={{ base: '12px', lg: '32px' }}
            fontWeight={600}
            color="darkText"
            whiteSpace="nowrap"
        >
            {bookingSessionType.price && (
                <Flex alignItems="center" gap="8px">
                    <CurrencyIcon />
                    <Text color="darkText">$ {bookingSessionType.price}</Text>
                </Flex>
            )}
            {bookingSessionType.duration && (
                <Flex alignItems="center" gap="8px">
                    <ClockIcon />
                    <Text color="darkText">
                        {bookingSessionType.duration} {durationString}
                    </Text>
                </Flex>
            )}
            {['address', 'url', 'phone'].includes(bookingSessionType.locationType ?? '') && (
                <Flex alignItems="center" gap="8px" whiteSpace="nowrap" overflowX="hidden">
                    {locationIcon}
                    <Text
                        color="darkText"
                        title={locationValue}
                        overflowX="hidden"
                        textOverflow="ellipsis"
                    >
                        {locationValue}
                    </Text>
                </Flex>
            )}
            {bookingSession?.startTime && (
                <Flex alignItems="center" gap="8px">
                    <CalendarIcon />
                    <Text color="darkText">
                        {moment(bookingSession.startTime).format('dddd, LL - LT')}
                    </Text>
                </Flex>
            )}
        </Flex>
    );
}
