import React, { useMemo } from 'react';
import { ChakraProvider, Flex, Heading, extendTheme } from '@chakra-ui/react';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { withI18NextTranslate } from 'sp-ui';
import { getTheme } from 'ts/common/components/gallery';
import { IFetchHookResponse, useQuery } from 'ts/common/hooks';
import Layout from 'client_react/booking/components/Layout';
import { IBookingSessionType } from 'client_react/booking/types';
import { useBootstrapData, useClientApiFetch, withBootstrapData } from 'client_react/bootstrap';
import Header from './Header';
import PageContainer from './PageContainer';
import { SessionSummary } from './SessionSummary';
import { QUERY } from './common';
import useBookingSession from './useBookingSession';

type ClientBookingData = ReturnType<typeof useBookingSession> & {
    bookingSessionType: IFetchHookResponse<IBookingSessionType>;
};

const App = withBootstrapData(() => {
    const navigate = useNavigate();
    const { translations } = useBootstrapData();
    const PageContainerWithTranslations = useMemo(
        () =>
            withI18NextTranslate<{ children: React.ReactNode }>(
                ({ children }: { children: React.ReactNode }) => (
                    <PageContainer>{children}</PageContainer>
                ),
                translations
            ),
        [translations]
    );

    const { sessionTypeSlug } = useParams();
    const query = useQuery();
    const bookingSessionId = query.get(QUERY.BOOKING_SESSION_ID);

    const { response: bookingSessionType } = useClientApiFetch<IBookingSessionType>(
        `booking-session-type/${sessionTypeSlug}`
    );

    if (bookingSessionType?.status === 404) {
        navigate('/not-found');
    }

    const theme = useMemo(
        () =>
            bookingSessionType?.brandTheme
                ? extendTheme(getTheme(bookingSessionType.brandTheme))
                : undefined,
        [bookingSessionType?.brandTheme]
    );

    const { bookingSession, updateBookingSession, navigateWithSession, isPending, submitPayment } =
        useBookingSession(bookingSessionId);

    const clientBookingData: ClientBookingData = useMemo(
        () => ({
            bookingSessionType,
            bookingSession,
            updateBookingSession,
            navigateWithSession,
            isPending,
            submitPayment
        }),
        [
            bookingSession,
            bookingSessionType,
            navigateWithSession,
            isPending,
            submitPayment,
            updateBookingSession
        ]
    );

    // something more elegant would be nice
    if (!theme) {
        return null;
    }

    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <PageContainerWithTranslations>
                <Header brandName={bookingSessionType?.brandName ?? ''} />
                <Layout>
                    {bookingSessionType && (
                        <Flex direction="column" gap="16px" marginBottom="24px">
                            <Heading as="h1" margin={0} size="2xl" color="brand.primary">
                                {bookingSessionType.name}
                            </Heading>
                            <SessionSummary
                                bookingSessionType={bookingSessionType}
                                bookingSession={bookingSession}
                            />
                        </Flex>
                    )}

                    <Outlet context={clientBookingData} />
                </Layout>
            </PageContainerWithTranslations>
        </ChakraProvider>
    );
});

export function useClientBookingData() {
    return useOutletContext<ClientBookingData>();
}

export default App;
