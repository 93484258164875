import React, { FC } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import {
    ClientBookingApp,
    ConfirmationPage,
    ContactInformationPage,
    DateAndTimePage,
    PaymentPage
} from './booking';
import { PARAM, ROUTE } from './booking/common';
import {
    AboutPage,
    App as BrandApp,
    CategoryPage,
    ContactPage,
    IndexPage,
    NotFoundPage
} from './brand';
import { EventCategoriesDataProvider } from './brand/data/eventCategoriesData';
import { EventsDataProvider } from './brand/data/eventsData';
import { GlobalStyles } from './global';

const EventsAndEventCategoriesDataProvider = ({ children }) => (
    <EventsDataProvider>
        <EventCategoriesDataProvider>{children}</EventCategoriesDataProvider>
    </EventsDataProvider>
);

const ClientApp: FC = () => {
    return (
        <>
            <GlobalStyles />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<BrandApp />}>
                        <Route path="contact" element={<ContactPage />} />
                        <Route path="about" element={<AboutPage />} />
                        <Route
                            path="category/:categoryId"
                            element={
                                <EventsAndEventCategoriesDataProvider>
                                    <CategoryPage />
                                </EventsAndEventCategoriesDataProvider>
                            }
                        />
                        <Route
                            index
                            element={
                                <EventsAndEventCategoriesDataProvider>
                                    <IndexPage />
                                </EventsAndEventCategoriesDataProvider>
                            }
                        />
                    </Route>
                    <Route
                        path={`/booking/:${PARAM.SESSION_TYPE_SLUG}`}
                        element={<ClientBookingApp />}
                    >
                        <Route path={ROUTE.DATE_AND_TIME} element={<DateAndTimePage />} />
                        <Route
                            path={ROUTE.CONTACT_INFORMATION}
                            element={<ContactInformationPage />}
                        />
                        <Route path={ROUTE.PAYMENT} element={<PaymentPage />} />
                        <Route path={ROUTE.CONFIRMATION} element={<ConfirmationPage />} />
                        <Route index element={<DateAndTimePage />} />
                    </Route>
                    <Route path="*" element={<NotFoundPage />} />
                </Routes>
            </BrowserRouter>
        </>
    );
};

export default ClientApp;
