import React, { useCallback, useMemo, useState } from 'react';
import { Skeleton } from '@chakra-ui/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useTranslate } from 'ts/common';
import { ButtonProps } from 'ts/common/components/gallery';
import { useClientBookingData } from 'client_react/booking/App';
import { ROUTE } from '../common';
import ResponsiveButtonGroup from './ResponsiveButtonGroup';

export const PaymentForm: React.FC = () => {
    const stripe = useStripe();
    const elements = useElements();
    const { isPending, submitPayment, navigateWithSession } = useClientBookingData();

    const [areElementsReady, setAreElementsReady] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const t = useTranslate('booking.payment');

    const handleSubmit = useCallback(
        async (event) => {
            event.preventDefault();

            if (!stripe || !elements) {
                return;
            }

            setIsSubmitting(true);

            // Trigger form validation and wallet collection
            const { error: submitError } = await elements.submit();

            if (submitError) {
                setIsSubmitting(false);

                // TODO: Remove debug logging
                // eslint-disable-next-line no-console
                console.log(
                    'submitError',
                    JSON.parse(JSON.stringify(submitError ?? `undefined var: ${submitError}`))
                );

                return;
            }

            const { error, paymentMethod } = await stripe.createPaymentMethod({
                elements
            });

            if (error) {
                setIsSubmitting(false);

                // TODO: handle
                console.log(error.message || t('error.cardDeclined'));
                return;
            }

            // TODO: Remove debug logging
            // eslint-disable-next-line no-console
            console.log(
                'paymentMethod',
                JSON.parse(JSON.stringify(paymentMethod ?? `undefined var: ${paymentMethod}`))
            );

            await submitPayment({
                paymentAmountType: 'full', // TODO Pass in either 'full' or 'retainer' based on the user's choice
                paymentMethodId: paymentMethod.id
            });

            setIsSubmitting(false);
        },
        [elements, stripe, submitPayment, t]
    );

    const buttons = useMemo<ButtonProps[]>(
        () => [
            {
                text: t('back'),
                variant: 'outline primary',
                onClick: () => navigateWithSession(ROUTE.CONTACT_INFORMATION)
            },
            {
                text: t('bookSession'),
                onClick: handleSubmit,
                variant: 'primary',
                isDisabled: !stripe || !elements || isPending || isSubmitting,
                type: 'submit'
            }
        ],
        [elements, handleSubmit, isPending, isSubmitting, navigateWithSession, stripe, t]
    );

    if (!stripe || !elements) {
        return null;
    }

    return (
        <form onSubmit={handleSubmit}>
            <Skeleton
                display="flex"
                flexDirection="column"
                isLoaded={areElementsReady}
                marginX="auto"
            >
                <PaymentElement onReady={() => setAreElementsReady(true)} />

                <ResponsiveButtonGroup buttons={buttons} marginTop="32px" />
            </Skeleton>
        </form>
    );
};
