import React, { useCallback, useMemo } from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { ButtonProps } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import ResponsiveButtonGroup from 'client_react/booking/components/ResponsiveButtonGroup';
import { useClientBookingData } from './App';
import { ROUTE } from './common';

const ContactInformationPage = () => {
    const { bookingSessionType, isPending, updateBookingSession, navigateWithSession } =
        useClientBookingData();
    const t = useTranslate('booking.contactInformationPage');

    const handleContinueClick = useCallback(async () => {
        // TODO Replace mock data with actual form data
        const success = await updateBookingSession({
            contact: {
                type: 'contact',
                firstName: 'Alex',
                lastName: 'C',
                phone: '+1-123-456-7890',
                email: 'alex+client@shootproof.com'
            }
        });

        if (success) {
            navigateWithSession(ROUTE.PAYMENT);
        }
    }, [navigateWithSession, updateBookingSession]);

    const buttons = useMemo<ButtonProps[]>(
        () => [
            {
                text: t('back'),
                onClick: () => navigateWithSession(ROUTE.DATE_AND_TIME),
                variant: 'outline primary'
            },
            {
                text: t('continue'),
                onClick: handleContinueClick,
                isDisabled: isPending,
                variant: 'primary'
            }
        ],
        [handleContinueClick, isPending, navigateWithSession, t]
    );

    if (!bookingSessionType) {
        return null;
    }

    return (
        <Flex direction="column" gap="24px">
            <Heading as="h3" size="lg" margin={0}>
                Contact Information
            </Heading>

            <Text fontWeight={600} textAlign="center">
                [Contact Form Here]
            </Text>
            <ResponsiveButtonGroup buttons={buttons} />
        </Flex>
    );
};

export default ContactInformationPage;
