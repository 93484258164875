import React from 'react';
import { Box, Flex, styled } from 'sp-ui';
import { useTranslate } from 'ts/common/hooks';
import ContactForm from '../components/ContactForm';
import { ThemedHeading, ThemedParagraph } from '../components/themed';
import { usePostContactForm } from '../hooks/usePostContactForm';

const ContactPageContainer = styled(Flex)(
    ({ theme }) => `
        height: 100%;
        flex-direction: column;
        min-height: 100vh;

        ${theme.responsive.smallScreenOnly`
            padding-top: 21px;
        `}
    `
);
const PageContent = styled(Box)`
    height: 100%;
    flex: 1;
`;

const ContactPage: React.FC = () => {
    const t = useTranslate('contactPage');
    const { errors, postContactForm, submitted } = usePostContactForm();

    return (
        <ContactPageContainer>
            {submitted ? (
                <ThemedParagraph data-testid="brand-contact-form-thank-you-message">
                    {t('submittedMessage')}
                </ThemedParagraph>
            ) : (
                <PageContent>
                    <ThemedHeading>{t('header')}</ThemedHeading>
                    <ContactForm errors={errors} onSubmit={postContactForm} />
                </PageContent>
            )}
        </ContactPageContainer>
    );
};

export default ContactPage;
