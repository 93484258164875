import { Box, Flex, styled } from 'sp-ui';

export const TileContainer = styled(Flex)`
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-evenly;
    margin: 0 auto;

    @media only screen and (min-width: 632px) {
        width: 624px;
    }

    @media only screen and (min-width: 944px) {
        width: 936px;
    }

    @media only screen and (min-width: 1280px) {
        width: 1260px;
    }
`;

export const TextDisplayBox = styled(Box)(
    ({ theme }) => `
        a {
            color: ${theme.brand.colors.user.secondary};
        }
        p {
            margin: 0 0 1em;
            line-height: 1.71429em
        }
        ul, ol {
            padding-inline-start: 40px;
            color: ${theme.brand.colors.common.gray};
        }
        h1, h2, h3, h4, h5, h6 {
            color: ${theme.brand.colors.common.gray};
            font-family: ${theme.brand.fonts.primary};
            margin-top: 24px;
            font-weight: 700;
        }
        h1 {
            font-size: 32px;
        }
        h2 {
            font-size: 24px;
        }
        h3 {
            font-size: 20px;
        }
        h4 {
            font-size: 16px;
        }
        h5, h6 {
            font-size: 12px;
        }
        hr {
            margin: 20px 0;
            color: ${theme.brand.colors.theme.secondary.medium};
        }

        .align-left {
            text-align: left;
        }

        .align-center {
            text-align: center;
        }

        .align-right {
            text-align: right;
        }

        .align-justify {
            text-align: justify;
        }
    `
);

export const PageText = styled(TextDisplayBox)(
    ({ theme }) => `
    @media (min-width: 1020px) {
        max-width: 731px;
    }

    color: ${theme.brand.colors.common.gray};
    max-width: 676px;
    padding: 0 20px 20px 20px;
    flex: 1;
    text-align: left;
    align-self: center;
    margin: auto;
    `
);
