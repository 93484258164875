import React from 'react';
import { Flex, Heading } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';
import { useClientBookingData } from 'client_react/booking/App';
import { PaymentFormContainer } from 'client_react/booking/components/PaymentFormContainer';

const PaymentPage: React.FC = () => {
    const { bookingSession, bookingSessionType } = useClientBookingData();

    const t = useTranslate('booking.payment');

    if (!bookingSession || !bookingSessionType) {
        return null;
    }

    const currencyCode = 'USD'; // TODO: Fix

    return (
        <Flex direction="column" gap="16px">
            <Heading as="h3" size="lg" margin={0}>
                {t('billingInformation')}
            </Heading>
            {!!bookingSession.price && (
                <PaymentFormContainer
                    amount={bookingSession.price}
                    currencyCode={currencyCode}
                    stripeConfigId={bookingSessionType.stripeConfigId}
                    stripePublicKey={bookingSessionType.stripePublicKey}
                    stripeStandardAccountId={bookingSessionType.stripeStandardAccountId}
                />
            )}
        </Flex>
    );
};

export default PaymentPage;
