import React, { FC, MouseEventHandler } from 'react';
import { ButtonProps, Button as ChakraButton, useBreakpointValue } from '@chakra-ui/react';

export interface Props extends ButtonProps {
    /** true only if the button is disabled */
    disabled?: boolean;
    /** true only if the button should fill 100% of the width of its parent */
    fullWidth?: boolean;
    /** true only if the button should show a loading indicator */
    isLoading?: boolean;
    /** function to be called on click */
    onClick?: MouseEventHandler<HTMLButtonElement>;
    /** text to render inside the button */
    text?: string;
    /** text to render inside the button at lg (large) breakpoint or higher */
    textLarge?: string;
    /** the visual variation used to render the button */
    variant?:
        | 'primary'
        | 'secondary light'
        | 'secondary dark'
        | 'ghost'
        | 'ghost primary'
        | 'outline primary'
        | 'text'
        | 'outline'
        | 'googlePhotos';
}

const Button: FC<Props> = ({
    disabled,
    fullWidth,
    isLoading,
    onClick,
    text,
    textLarge = text,
    variant = 'primary',
    ...rest
}) => {
    const buttonText = useBreakpointValue({
        base: text,
        lg: textLarge
    });

    return (
        <ChakraButton
            isDisabled={isLoading || disabled}
            isLoading={isLoading}
            loadingText={isLoading ? buttonText : undefined}
            onClick={isLoading ? undefined : onClick}
            variant={variant}
            width={fullWidth ? 'full' : undefined}
            {...rest}
        >
            {buttonText}
        </ChakraButton>
    );
};

export default Button;
