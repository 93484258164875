import React, { useMemo, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { ButtonProps } from 'ts/common/components/gallery';
import { IBookingSessionType } from 'client_react/booking/types';
import ResponsiveButtonGroup from './components/ResponsiveButtonGroup';

type PanelType = 'description' | 'date' | 'time';

type Props = {
    bookingSessionType: IBookingSessionType;
    onSubmit: () => void;
};

const MobileDateAndTimePage = ({ bookingSessionType, onSubmit }: Props) => {
    const [activePanel, setActivePanel] = useState<PanelType>('description');

    const buttons = useMemo<Record<PanelType, Array<ButtonProps>>>(
        () => ({
            description: [
                { text: 'Continue', onClick: () => setActivePanel('date'), variant: 'primary' }
            ],
            date: [
                {
                    text: 'Back',
                    onClick: () => setActivePanel('description'),
                    variant: 'outline primary'
                },
                { text: 'Continue', onClick: () => setActivePanel('time'), variant: 'primary' }
            ],
            time: [
                {
                    text: 'Back',
                    onClick: () => setActivePanel('date'),
                    variant: 'outline primary'
                },
                { text: 'Continue', onClick: onSubmit, variant: 'primary' }
            ]
        }),
        [onSubmit]
    );

    return (
        <Flex flexDirection="column">
            {activePanel === 'description' && <Text>{bookingSessionType.description}</Text>}
            {activePanel === 'date' && <Text>[Date Picker Here]</Text>}
            {activePanel === 'time' && <Text>[Time Picker Here]</Text>}

            <ResponsiveButtonGroup buttons={buttons[activePanel]} />
        </Flex>
    );
};

export default MobileDateAndTimePage;
